import { useBlocksTheme } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';
import {
	BlockWithAttributes,
	ContentBlock,
} from '@faustwp/blocks/dist/mjs/components/WordPressBlocksViewer';
import { getStyles } from '@faustwp/blocks/dist/mjs/utils/get-styles/getStyles';
import { type CoreParagraphFragFragment } from '@graphqlTypes/graphql';

export type CoreParagraphFragmentProps = ContentBlock & CoreParagraphFragFragment;

export const CoreParagraph = ( props: CoreParagraphFragmentProps ) => {
	const theme = useBlocksTheme();
	const style = getStyles( theme, { ...( props as unknown as BlockWithAttributes ) } );

	const { attributes, cssClassNames } = props;

	const classNames = Array.from( new Set( [ cssClassNames || [], attributes?.cssClassName ] ) )
		.filter( Boolean )
		.join( ' ' );

	return (
		<p
			style={ style }
			className={ classNames }
			dangerouslySetInnerHTML={ { __html: attributes?.content ?? '' } }
		/>
	);
};
